import '../styles/spinner.scss';

const Spinner = () => {
   return (
      <>
         <div
            class='loader'
            style={{
               position: 'fixed',
               zIndex: '100',

               top: '40%',
            }}>
            Loading...
         </div>
      </>
   );
};

export default Spinner;
