import styles from "./teacher.module.css";
import teacherImage from "../../assets/images/teacher.svg";
import bottomRight from "../../assets/images/bottomRight.svg";
import bottomLeft from "../../assets/images/bottomLeft.svg";
import React, { useEffect } from "react";

const URL = `https://www.coschool.ai/unified-login`;

const MainPage = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = URL; // Replace with your target URL
    }, 10000); // 10000 milliseconds = 10 seconds

    // Cleanup function to clear the timeout if the component is unmounted before the timer ends
    return () => clearTimeout(timer);
  }, []);
  return (
    <div className={styles["teacher-section"]}>
      <div className={styles["container"]}>
        <div className={styles.left}>
          <div className={styles["title"]}>Innerscore is now Coschool.</div>
          <div className={styles["description"]}>
            We've upgraded to elevate your education experience through
            AI-enhanced solutions. Visit us at
            <a className={styles.link} href={URL}>
              Coschool.ai
            </a>
          </div>
        </div>
        <div className={styles.right}>
          <img
            src={teacherImage}
            alt="teacherImage"
            className={styles["teacherImg"]}
          />
        </div>
      </div>
      <div className={styles["bottom-right"]}>
        <img
          src={bottomRight}
          alt="bottomRight"
          className={styles["bottomRightImg"]}
        />
      </div>
      <div className={styles["bottom-left"]}>
        <img
          src={bottomLeft}
          alt="bottomLeft"
          className={styles["bottomLeftImg"]}
        />
      </div>
    </div>
  );
};

export default MainPage;
