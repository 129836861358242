import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";

import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/footer/footer";

import HomePage from "./pages/homePage";
import AboutUs from "./pages/aboutUs";
import ContactUs from "./pages/contactUs";
import Parents from "./pages/parents";
import PartnershipForm from "./pages/partnershipForm";
import Partnership from "./pages/partnership";
import PrivacyPolicy from "./pages/privacyPolicy";
import Students from "./pages/students";
import WorkWithUs from "./pages/workWithUs";
import CoschoolRedirection from "./pages/coschoolRedirection";

import ReactGA from "react-ga";

const TRACKING_ID = "UA-245282317-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <>
      {/* <Navbar /> */}
      <Routes>
        <Route path="/" element={<CoschoolRedirection />} />
        {/* <Route path="/old" element={<HomePage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/parents" element={<Parents />} />
        <Route path="/partnership" element={<Partnership />} />
        <Route path="/partnership-form" element={<PartnershipForm />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/students" element={<Students />} />
        <Route path="/work-with-us" element={<WorkWithUs />} /> */}
        <Route path="*" element={<Navigate to={"/"}/>} />
      </Routes>
      {/* <Footer /> */}
    </>
  );
}

export default App;
