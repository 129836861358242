import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './utils/scrolltop';
import App from './App';
import './index.css';

// Import for font
import './fonts/capital-gothic-medium.ttf';
import './fonts/capital-gothic-regular.ttf';
import './fonts/capital-gothic-extrabold.ttf';
import './fonts/capital-gothic-bold.ttf';

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <React.StrictMode>
      <BrowserRouter>
         <ScrollToTop>
            <App />
         </ScrollToTop>
      </BrowserRouter>
   </React.StrictMode>
);

reportWebVitals();
